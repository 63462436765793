const FIREBASE_CONFIGS = {
    dev: {
        apiKey: "AIzaSyB1dvK74L-8KdNF7LJP5eug7ZJI0PFasLw",
        authDomain: "bookezy-dev.firebaseapp.com",
        projectId: "bookezy-dev",
        storageBucket: "bookezy-dev.appspot.com",
        messagingSenderId: "738641927040",
        appId: "1:738641927040:web:2ac47ab6eb9f8a1d8c7f65",
        measurementId: "G-8XF9XMTZEY"
    },
    prod: {
        apiKey: "AIzaSyCGMTpgeuLKBkNQxqGkiCjSrfFpbR3pjcw",
        authDomain: "bookezy-lk.firebaseapp.com",
        projectId: "bookezy-lk",
        storageBucket: "bookezy-lk.appspot.com",
        messagingSenderId: "763338708377",
        appId: "1:763338708377:web:afcbebc0da1dfcb8572f08",
        measurementId: "G-J3KMJ8SRVV"
    }
}

const ADMIN_DOMAINS = {
    dev: 'bookezy-dev-admin.web.app',
    staging: 'smartbooking-staging-admin.web.app',
    prod: 'admin.bookezy.lk'
}

const CLIENT_DOMAINS = {
    dev: 'bookezy-dev.web.app',
    staging: 'smartbooking-staging.web.app',
    prod: 'bookezy.lk'
}

const API_BASE_URLS = {
    dev: 'https://dev.hatchyard.io/b4u-core',
    staging: 'https://dev.hatchyard.io/b4u-core', // Change to staging baseURL
    prod: 'https://api.bookezy.lk/b4u-core'
}

const FB_APP_IDS ={
    dev :'2099161353618198',
    staging: null,
    prod:null
}
const GOOGLE_SIGNIN_CLIENT_ID = {
    dev: "1047371924445-7t816eou3an7p37bbme8d8ksflrgcbl2.apps.googleusercontent.com",
    staging: null,
    prod: null
}

const CYBERSOURCE_IDS = {
    dev: {
        URL: 'https://testsecureacceptance.cybersource.com/pay'
    },
    staging: {},
    prod: {
        URL: 'https://secureacceptance.cybersource.com/pay'
    },
}

export const DEFAULT_COMMON_SHOP_CONFIGURATIONS = {
    AppointmentCancellationClient: true,
    FullDayBookingClient: false,
}

export const BUSINESS_TYPES = {
    1: {
        business_category_pluarl: 'Employees',
        services: 'Services',
        service: 'Select Service',
        business_category: 'Select Staff',
        shop_configurations: DEFAULT_COMMON_SHOP_CONFIGURATIONS
    },
    2: {
        business_category_pluarl: 'Instructors',
        services: 'Services',
        service: 'Service',
        business_category: 'Select Instructor',
        shop_configurations: DEFAULT_COMMON_SHOP_CONFIGURATIONS
    },
    3: {
        business_category_pluarl: 'Facilities',
        services: 'Sports',
        service: 'Select Sport',
        business_category: 'Select Facility',
        shop_configurations: {
            ...DEFAULT_COMMON_SHOP_CONFIGURATIONS,
            AppointmentCancellationClient: false,
            FullDayBookingClient: true
        }
    },
    4: {
        business_category_pluarl: 'Employees',
        services: 'Services',
        service: 'Select Service',
        business_category: 'Select Staff',
        shop_configurations: DEFAULT_COMMON_SHOP_CONFIGURATIONS
    }
}

export const configs = {
    fbAppId: FB_APP_IDS[process.env.REACT_APP_STAGE],
    country: 'LK',
    countryCode: '94',
    smsGateway: 'SL',
    currencyPrefix: 'Rs.',
    siteName: 'Bookezy',
    deployementName: 'Bookezy',
    phoneNumberExample: 'eg: 0712345678 (10 digits format)',
    isPricingActivated: true,
    phoneRegex: new RegExp("^07[0-9]{8}?$"),

    adminDomain: ADMIN_DOMAINS[process.env.REACT_APP_STAGE],
    clientDomain: CLIENT_DOMAINS[process.env.REACT_APP_STAGE],
    firebase: FIREBASE_CONFIGS[process.env.REACT_APP_STAGE],
    baseURL: API_BASE_URLS[process.env.REACT_APP_STAGE],

    googleSignInClientId: GOOGLE_SIGNIN_CLIENT_ID[process.env.REACT_APP_STAGE],

    cybersource: CYBERSOURCE_IDS[process.env.REACT_APP_STAGE]
}
